import './Header.css';

const Footer = () => {
  return (
    <div className="footer-text">
        designed by andre lee
    </div>
  )
}

export default Footer